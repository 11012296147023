import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Button,
  Cushion,
  Grid,
  Image,
  MaxWidth,
  Rectangle,
  ScrollBox,
  Shadow,
  Space,
  Typography,
} from '@pitchero/react-ui';
import TopBannerVideoPlayerSwitch from 'components/adverts/top-banner-video-player-switch';
import { Trans } from 'lib/i18n';
import TEAM_PROP_TYPES from 'lib/prop-types/team';
import useBreakpoint from 'lib/use-breakpoint';
import MobileSubHeader from '../mobile-sub-header';
import InfoPanel from './info-panel';

const infoCharacterCount = 230;

const TeamHomepageInfo = ({ club, image, imageText, title, team, welcomeContent }) => {
  const hasContent = welcomeContent !== null;
  const contentHasHTML = /([\w<>/]+|\.)/g.test(welcomeContent);
  const contentExceedsCharLimit = hasContent && welcomeContent.length > infoCharacterCount;

  let hasLongWelcomeContent = contentExceedsCharLimit;

  // snippet is set to the welcome content
  let welcomeContentSnippet = null;

  // if the welcome content has HTML, display this content in a scrollbox
  if (contentHasHTML) {
    let htmlContent = welcomeContent;

    if (contentExceedsCharLimit) {
      htmlContent = `${htmlContent.substring(0, infoCharacterCount)}...`;
    }

    welcomeContentSnippet = (
      <ScrollBox style={{ maxHeight: 400 }} yOnly>
        <div className="bbcode-content" dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </ScrollBox>
    );
  } else if (welcomeContent) {
    // if the welcome content does not contain HTML but the character count is larger than
    // the default, substring this with a ... for a READ MORE feature.
    hasLongWelcomeContent = welcomeContent.length > infoCharacterCount && !contentHasHTML;
    welcomeContentSnippet = hasLongWelcomeContent
      ? `${welcomeContent.substring(0, infoCharacterCount)}...`
      : welcomeContent;
  }

  const [panelIsOpen, setPanelIsOpen] = useState(false);
  const { advertisingTier, sportId } = club;
  const isMobile = useBreakpoint();

  return (
    <>
      <Cushion
        top="medium"
        bottom="small"
        horizontal="small"
        responsive={[{ minWidth: 'tab', props: { horizontal: 'medium' } }]}
        component="div"
      >
        {isMobile && <MobileSubHeader title="Overview" transKey="teams:navigation_homepage" />}
        <MaxWidth component="div" maxWidth={1366} center style={{ position: 'relative' }}>
          <Cushion bottom="medium">
            <TopBannerVideoPlayerSwitch
              advertisingTier={advertisingTier}
              sportId={sportId}
              pageType="team.homepage"
            />
          </Cushion>
          <Grid
            alignItems="center"
            className="ie-grid-two-col"
            areas="'info image'"
            columns="1fr 1fr"
            columnGap="xxlarge"
            responsive={[
              {
                maxWidth: 'tab',
                props: {
                  columns: 'auto',
                  areas: "'image' 'info'",
                },
              },
            ]}
            rowGap="medium"
          >
            <div style={{ gridArea: 'info' }}>
              <Space bottom="small">
                <Typography
                  preset="tab--active"
                  responsive={[{ maxWidth: 'tab', props: { preset: 'tag--large' } }]}
                  color="primary"
                  component="h3"
                >
                  <Trans i18nKey="teams:homepage_header">Team Info</Trans>
                </Typography>
              </Space>
              <Space bottom="medium" responsive={[{ maxWidth: 'tab', props: { bottom: '0' } }]}>
                <Typography
                  preset="title--large"
                  responsive={[{ maxWidth: 'tab', props: { preset: 'title' } }]}
                  color="black"
                  component="h4"
                >
                  {title}
                </Typography>
              </Space>
              <Typography preset="body--large" color="dovegrey" component="div">
                {welcomeContentSnippet || (
                  <Space top="small" component="p">
                    <Trans
                      i18nKey="teams:homepage_welcome_placeholder"
                      params={{ teamName: team.name }}
                    >
                      {`Welcome to the ${team.name} Overview.`}
                    </Trans>
                  </Space>
                )}
              </Typography>
              {hasLongWelcomeContent && (
                <Space top="medium">
                  <Button
                    theme="primaryText"
                    style={{ paddingLeft: '0' }}
                    onClick={() => setPanelIsOpen(true)}
                  >
                    <Trans i18nKey="common:read_more">Read more</Trans>
                  </Button>
                </Space>
              )}
            </div>
            <div style={{ gridArea: 'image' }}>
              <Shadow shadow="dark">
                <Rectangle radius="rounded" style={{ cursor: 'pointer', display: 'block' }}>
                  <Image
                    src={image}
                    shimmerOnLoad
                    alt={team.name}
                    preset="card--large"
                    fullWidth
                    onClick={() => setPanelIsOpen(true)}
                  />
                </Rectangle>
              </Shadow>
            </div>
          </Grid>
        </MaxWidth>
      </Cushion>
      <InfoPanel
        image={image}
        imageText={imageText}
        welcomeContent={welcomeContent}
        isOpen={panelIsOpen}
        onClose={() => setPanelIsOpen(false)}
        teamName={team.name}
      />
    </>
  );
};

TeamHomepageInfo.defaultProps = {
  imageText: null,
  welcomeContent: null,
};

TeamHomepageInfo.propTypes = {
  club: PropTypes.shape({
    advertisingTier: PropTypes.number.isRequired,
    sportId: PropTypes.number.isRequired,
  }).isRequired,
  image: PropTypes.string.isRequired,
  imageText: PropTypes.string,
  title: PropTypes.string.isRequired,
  team: TEAM_PROP_TYPES.isRequired,
  welcomeContent: PropTypes.string,
};

export default TeamHomepageInfo;
