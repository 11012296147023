import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, FlexContainer, Space, Typography } from '@pitchero/react-ui';
import ArrowRightIcon from 'icons/ArrowRight';
import { Trans } from 'lib/i18n';
import { ACTIVITY_STREAM_MESSAGES_PROP_TYPE } from 'lib/prop-types/activity-stream-message';
import ActivityStream from 'components/activity-stream';
import ClubLink from 'components/club-link';
import IconWithTheme from 'components/icon-with-theme';

const TeamHomepageMedia = ({ fullWidth, media, teamId }) => {
  return (
    <div>
      <Space bottom="small">
        <Typography
          preset="tab--active"
          responsive={[{ maxWidth: 'tab', props: { preset: 'tag--large' } }]}
          color="primary"
          component="h3"
        >
          <Trans i18nKey="teams:homepage_media_header">Media</Trans>
        </Typography>
      </Space>
      <Space bottom="medium">
        <FlexContainer justifyContent="space-between" alignItems="center">
          <Typography preset="title" color="black" component="h4">
            <Trans i18nKey="common:gallery">Gallery</Trans>
          </Typography>
          <ClubLink route="media.albums.team" params={{ teamId }}>
            <a>
              <Typography preset="button" color="primary">
                <Trans i18nKey="common:view all">View all</Trans>
              </Typography>
              <Cushion left="xsmall">
                <IconWithTheme>
                  <ArrowRightIcon size={12} topOffset={1} color="primary" />
                </IconWithTheme>
              </Cushion>
            </a>
          </ClubLink>
        </FlexContainer>
      </Space>
      <ActivityStream messages={media} grid={fullWidth ? 'activityStream' : 'teamHomepageMedia'} />
    </div>
  );
};

TeamHomepageMedia.propTypes = {
  fullWidth: PropTypes.bool.isRequired,
  media: ACTIVITY_STREAM_MESSAGES_PROP_TYPE.isRequired,
  teamId: PropTypes.number.isRequired,
};

export default TeamHomepageMedia;
