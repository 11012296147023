import React from 'react';
import { Grid, Hide, Shimmer, ShimmerAvatar, ShimmerTypography } from '@pitchero/react-ui';

const FixtureOverviewPlaceHolder = () => (
  <Grid
    columns="1fr 80px 1fr"
    columnGap="small"
    rowGap="xsmall"
    responsive={[
      {
        minWidth: 360,
        props: {
          columns: '1fr 36px 70px 36px 1fr',
          columnGap: 'xxsmall',
          rowGap: 'xxsmall',
        },
      },
    ]}
    alignItems="center"
    justifyItems="center"
  >
    <div style={{ width: '100%' }}>
      <ShimmerTypography preset="name" width="80%" style={{ margin: '0 auto' }} />
    </div>
    <Hide maxWidth={359} component="div" style={{ alignText: 'center' }}>
      <ShimmerAvatar size={36} />
    </Hide>
    <Shimmer height={36} width="100%" radius="rounded" />
    <Hide maxWidth={359} component="div" style={{ alignText: 'center' }}>
      <ShimmerAvatar size={36} />
    </Hide>
    <div style={{ width: '100%' }}>
      <ShimmerTypography preset="name" width="80%" style={{ margin: '0 auto' }} />
    </div>
  </Grid>
);

export default FixtureOverviewPlaceHolder;
