import React from 'react';
import PropTypes from 'prop-types';
import {
  Cushion,
  FlexContainer,
  Image,
  LinearGradient,
  Panel,
  Rectangle,
  ScrollBox,
  Shadow,
  Space,
  Typography,
} from '@pitchero/react-ui';
import { Trans } from 'lib/i18n';

const InfoPanel = ({ image, imageText, isOpen, onClose, teamName, welcomeContent }) => (
  <Panel isOpen={isOpen} onClose={onClose}>
    <FlexContainer flexDirection="column" style={{ height: '100%' }}>
      <Rectangle fill="primary">
        <Cushion vertical="medium" horizontal="large">
          <Typography preset="title" color="primaryContrast" component="div" align="center">
            {teamName}
          </Typography>
        </Cushion>
      </Rectangle>
      <LinearGradient
        component="div"
        angle={0}
        stops={[
          [0, 'white'],
          ['50%', 'white'],
          ['50%', 'primary'],
        ]}
      >
        <Cushion horizontal="medium" component="div">
          <Shadow>
            <Rectangle radius="rounded" fill="white">
              <Image src={image} alt={teamName} preset="teamPhotoPanel" fullWidth />
            </Rectangle>
          </Shadow>
        </Cushion>
      </LinearGradient>

      <Cushion all="medium" style={{ flex: 1 }}>
        <ScrollBox yOnly>
          {imageText && (
            <Space bottom="large">
              <Space bottom="small">
                <Typography preset="tag--large" color="dustygrey" component="h3">
                  <Trans i18nKey="teams:homepage_photo_header">Players Pictured</Trans>
                </Typography>
              </Space>
              <Typography preset="body--large" color="black" component="div">
                {imageText}
              </Typography>
            </Space>
          )}
          {welcomeContent && (
            <Space bottom="large">
              <Space bottom="small">
                <Typography preset="tag--large" color="dustygrey" component="h3">
                  <Trans i18nKey="teams:homepage_welcome_header">About the team</Trans>
                </Typography>
              </Space>
              <Typography preset="body--large" color="black" component="div">
                <div
                  className="bbcode-content"
                  dangerouslySetInnerHTML={{ __html: welcomeContent }}
                />
              </Typography>
            </Space>
          )}
        </ScrollBox>
      </Cushion>
    </FlexContainer>
  </Panel>
);

InfoPanel.defaultProps = {
  imageText: null,
  welcomeContent: null,
};

InfoPanel.propTypes = {
  image: PropTypes.string.isRequired,
  imageText: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  teamName: PropTypes.string.isRequired,
  welcomeContent: PropTypes.string,
};

export default InfoPanel;
