import { find, get, includes } from 'lodash';

export const isLoadingTeamHomepage = (state, teamId) => {
  const loading = get(state, 'teams.homepage.currentlyLoadingTeams');
  return includes(loading, teamId);
};

export const hasLoadedTeamHomepage = (state, teamId) => {
  const loaded = get(state, 'teams.homepage.loadedTeams');
  return includes(loaded, teamId);
};

export const getTeamHomepageLoadingError = (state, teamId) =>
  find(get(state, 'teams.homepage.loadingErrors'), { teamId });

export const getTeamHomepagePageData = (state, teamId) =>
  find(get(state, 'teams.homepage.pageData'), { teamId });
