import React from 'react';
import CLUB_PROP_TYPES from 'lib/prop-types/club';
import TEAM_PROP_TYPES from 'lib/prop-types/team';
import { getClubTeam } from 'store/clubs/selectors';
import Club404 from '../components/errors/club-404';

const withTeam = (WrappedPage) => {
  class HOC extends React.Component {
    static async getInitialProps(context) {
      const { club } = context;
      const teamId = parseInt(context.query.teamId, 10);
      const initialProps = {
        activeSection: 'teams',
        namespacesRequired: ['common', 'teams'],
        teamId,
      };

      if (!club) {
        return initialProps;
      }

      const team = getClubTeam(context.reduxStore.getState(), context.club.id, teamId);
      if (!team) {
        const { res } = context;
        if (res) {
          res.statusCode = 404;
        }

        return initialProps;
      }

      let pageProps = {};
      if (typeof WrappedPage.getInitialProps === 'function') {
        pageProps = await WrappedPage.getInitialProps({ ...context, team });
      }

      return {
        ...initialProps,
        team,
        ...pageProps,
      };
    }

    render() {
      const { club, team } = this.props;
      return team ? <WrappedPage {...this.props} /> : <Club404 club={club} />;
    }
  }

  HOC.propTypes = {
    club: CLUB_PROP_TYPES.isRequired,
    team: TEAM_PROP_TYPES.isRequired,
  };

  return HOC;
};

export default withTeam;
