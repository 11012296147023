import PropTypes from 'prop-types';

export const ACTIVITY_STREAM_MESSAGE_PROP_TYPE = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
});

export const ACTIVITY_STREAM_MESSAGES_PROP_TYPE = PropTypes.arrayOf(
  ACTIVITY_STREAM_MESSAGE_PROP_TYPE,
);
