import PageDataLoader from 'lib/page-data/loader';
import PageKey from 'lib/page-data/key';
import {
  TEAM_HOMEPAGE_REQUEST,
  TEAM_HOMEPAGE_RECEIVE,
  TEAM_HOMEPAGE_FAILURE,
  PAGE_DATA_LOAD,
} from 'store/action-types';

export const fetchHomepageData = (clubId, teamId, request) => async (dispatch) => {
  try {
    dispatch({ type: TEAM_HOMEPAGE_REQUEST, payload: { clubId, teamId } });
    const pageKey = new PageKey(clubId, 'team_homepage', { teamId });
    const pageData = await PageDataLoader.getPageData(
      new PageKey(clubId, 'team_homepage', { teamId }),
      request,
    );
    dispatch({
      type: TEAM_HOMEPAGE_RECEIVE,
      payload: { clubId, teamId, data: pageData.data },
    });
    dispatch({ type: PAGE_DATA_LOAD, payload: pageKey.toString() });
    return {};
  } catch (error) {
    let statusCode = 500;
    if (error) {
      statusCode = error.errorStatusCode || statusCode;
    }
    dispatch({
      type: TEAM_HOMEPAGE_FAILURE,
      payload: { clubId, teamId, statusCode, error },
    });
    return { error };
  }
};
