import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Cushion, Rectangle, Shadow, Space, Typography } from '@pitchero/react-ui';
import { find } from 'lodash';
import { Trans } from 'lib/i18n';
import Table from 'components/table/shortened';
import ClubLink from 'components/club-link';
import TABLE_PROP_TYPES from 'lib/prop-types/table';
import TableSelect from './table-select';

const TeamHomepageTables = ({ tables, teamId }) => {
  const [table, selectTable] = useState(tables[0]);

  const onSelectTableHandler = (id) => {
    selectTable(find(tables, { id }));
  };

  return (
    <div>
      <Space bottom="small">
        <Typography
          preset="tab--active"
          responsive={[{ maxWidth: 'tab', props: { preset: 'tag--large' } }]}
          color="primary"
          component="h3"
        >
          <Trans i18nKey="common:homepage_subtitle_table">Table</Trans>
        </Typography>
      </Space>
      <Space bottom="medium">
        <Typography preset="title" color="black" component="h4">
          <Trans i18nKey="common:homepage_subtitle_league_position">League Position</Trans>
        </Typography>
      </Space>

      <Shadow>
        {tables.length > 1 && (
          <Space bottom="medium" component="div">
            <TableSelect
              tables={tables}
              selectedTableId={table.id}
              onSelectTable={onSelectTableHandler}
            />
          </Space>
        )}

        {table && (
          <Rectangle fill="white" radius={tables.length > 1 ? 'roundedBottom' : 'rounded'}>
            <Table {...table} />
            <ClubLink route="team.league_table" params={{ teamId, tableId: table.id }}>
              <a>
                <Cushion vertical="medium">
                  <Typography preset="button" align="center" component="div" color="primary">
                    <Trans i18nKey="common:view_full_table">View full table</Trans>
                  </Typography>
                </Cushion>
              </a>
            </ClubLink>
          </Rectangle>
        )}
      </Shadow>
    </div>
  );
};

TeamHomepageTables.propTypes = {
  teamId: PropTypes.number.isRequired,
  tables: PropTypes.arrayOf(TABLE_PROP_TYPES).isRequired,
};

export default TeamHomepageTables;
