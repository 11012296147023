import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, Rectangle, Shadow, Space, Typography } from '@pitchero/react-ui';
import { DateTime } from 'luxon';
import CLUB_PROP_TYPES from 'lib/prop-types/club';
import { Trans } from 'lib/i18n';
import { groupBy, map } from 'lodash';
import CalendarTrainingSession from 'components/calendar/calendar-training-session';

const Training = ({ club, trainingSessions }) => {
  return (
    <div>
      <Space bottom="small">
        <Typography
          preset="tab--active"
          responsive={[{ maxWidth: 'tab', props: { preset: 'tag--large' } }]}
          color="primary"
          component="h3"
        >
          <Trans i18nKey="teams:homepage_training_header">Training</Trans>
        </Typography>
      </Space>
      <Space bottom="medium">
        <Typography preset="title" color="black" component="h4">
          <Trans i18nKey="teams:homepage_training_subheader">Upcoming</Trans>
        </Typography>
      </Space>
      {map(
        groupBy(trainingSessions, (session) =>
          DateTime.fromISO(session.start).setZone(club.timezone).toFormat('yyyy-MM-01'),
        ),
        (sessions, monthStart) => (
          <Shadow key={monthStart}>
            <Rectangle fill="white" radius="rounded">
              <Rectangle fill="alabaster" radius="roundedTop">
                <Typography preset="tag--large" component="div">
                  <Cushion all="small">
                    {DateTime.fromISO(monthStart).toFormat('MMMM ')}
                    <Typography preset="tag--large" color="dustygrey">
                      {DateTime.fromISO(monthStart).toFormat('yyyy')}
                    </Typography>
                  </Cushion>
                </Typography>
              </Rectangle>
              {sessions.map((session, sessionIndex) => (
                <Cushion all="small" key={session.id}>
                  <Typography component="div" preset="tag--large" color="alto" align="left">
                    {DateTime.fromISO(session.start).setZone(club.timezone).toFormat('ccc, MMM d')}
                  </Typography>
                  <CalendarTrainingSession
                    club={club}
                    session={session}
                    noBorder={sessionIndex === sessions.length - 1}
                  />
                </Cushion>
              ))}
            </Rectangle>
          </Shadow>
        ),
      )}
    </div>
  );
};

Training.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
  trainingSessions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default Training;
